<template>
  <dialog-outline v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- display prompt -->
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :color="promptColorClass">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";
import { actionResultType } from "@/model/action/actionModel";

// services
import {
  fullRecordVersionName,
  fullRecordName
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";

export default {
  name: "ReleaseDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin, progressAbleMixin],
  props: {
    record: {
      type: Object,
      required: true
    },
    isFileChanged: {
      type: Boolean,
      required: true
    },
    releaseEvent: {
      type: Function,
      required: true
    }
  },
  computed: {
    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findRecordOperation(recordOperation.Release);
    },

    /**
     * download operation Label
     * @return {string} download operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Release Dialog title
     * @return {string} Release Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} ${this.record?.recordType}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordVersionName(this.record)}'`;
    },

    /**
     * OK command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * compute Release Action Command Prompt
     * @return {string} Release Action Command Prompt
     */
    promptText() {
      return `Do you want to ${this.action}?`;
    },

    /**
     * compute prompt Subtitle
     * @return {string} prompt Subtitle
     */
    promptSubtitle() {
      return this.isFileChanged
        ? "Warning: All Changes will be Lost!"
        : "Any way, No Changes were found.";
    },

    /**
     * compute prompt Hint (toolTip)
     * @return {string}  prompt Hint (toolTip)
     */
    promptHint() {
      return this.isFileChanged
        ? `${this.operationLabel} will Undo All Changes and change record state, so All Changes will be Lost!`
        : `${this.operationLabel} will only Change Record State, since No Changes were found.`;
    },

    /**
     * compute current prompt Type
     * @return {string} computed current prompt Type
     */
    currentPromptType() {
      return this.isFileChanged
        ? this.promptTypeName.warning
        : this.promptTypeName.info;
    },

    /**
     * full Record Name
     * @return {string} full Record Name
     */
    fullRecordName() {
      return fullRecordName(this.record);
    }
  },
  methods: {
    /**
     * Performs release
     */
    async onOk() {
      try {
        this.visiblePrompt = false;

        this.clearAlert();
        this.showProgress(this.formatProgressText(this.action));

        const result = await this.releaseEvent();

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlertError(
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.closeProgress();
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    }
  }
};
</script>
